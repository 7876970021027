import { ReactNode, useState } from 'react';
import { cellStyle, rowStyle, tableStyle } from './style';
import { ThemeUICSSObject } from 'theme-ui';
import Skeleton from 'react-loading-skeleton';
import { Checkbox } from '@odekoteam/doppio';

export const Table = ({
  children,
  ...rest
}: {
  children: ReactNode;
  id?: string;
}): JSX.Element => (
  <table sx={tableStyle} {...rest}>
    {children}
  </table>
);

export const TableHeader = ({
  labels,
  onHeaderSelected,
  isAllSelected,
  setIsAllSelected,
  ...rest
}: {
  labels: (ReactNode | string)[];
  isAllSelected?: boolean;
  setIsAllSelected?: React.Dispatch<React.SetStateAction<boolean>>;
  onHeaderSelected?: (value: boolean) => void;
}): JSX.Element => {
  return (
    <tr
      sx={{
        variant: 'text.tableHeader',
        backgroundColor: 'backgroundWeak',
        overflow: 'hidden',
      }}
    >
      {labels.map((label, index) => {
        const getBorderRadius = (): string => {
          if (index === 0) return '4px 0px 0px 4px';
          if (index === labels.length - 1) return '0px 4px 4px 0px';
          return '0px';
        };
        if (label === '<select>')
          return (
            <th
              key={index}
              sx={{ ...cellStyle, borderRadius: getBorderRadius() }}
              data-test-id="table-select"
            >
              <Checkbox
                isChecked={isAllSelected}
                onChange={(value): void => {
                  if (onHeaderSelected) onHeaderSelected(value);
                  if (setIsAllSelected) setIsAllSelected(value);
                }}
              />
            </th>
          );
        return (
          <th
            key={index}
            sx={{ ...cellStyle, borderRadius: getBorderRadius() }}
          >
            {label}
          </th>
        );
      })}
    </tr>
  );
};

export const TableBody = ({
  children,
  ...rest
}: {
  children: ReactNode;
}): JSX.Element => <tbody {...rest}>{children}</tbody>;

export const TableRow = ({
  children,
  ...rest
}: {
  children: ReactNode;
}): JSX.Element => (
  <tr {...rest} sx={rowStyle}>
    {children}
  </tr>
);

export const TableCell = ({
  style,
  children,
  onClick,
  ...rest
}: {
  style?: ThemeUICSSObject;
  onClick?: () => void;
  children: ReactNode;
}): JSX.Element => (
  <td {...rest} sx={{ ...cellStyle, ...style }} onClick={onClick}>
    {children}
  </td>
);

export const LoaderTable = ({
  numColumns,
  numRows = 3,
}: {
  numColumns: number;
  numRows?: number;
}): JSX.Element => {
  return (
    <>
      {Array.from({ length: numRows }, (_, i) => (
        <TableRow key={`loader-row-${i}`}>
          {Array.from({ length: numColumns }, (_, j) => (
            <TableCell key={`loader-cell-${j}`}>
              <Skeleton sx={{ height: '24px' }} />
            </TableCell>
          ))}
        </TableRow>
      ))}
    </>
  );
};

export const LoadingRow = ({ numCells }: { numCells: number }): JSX.Element => {
  return (
    <TableRow>
      {Array(numCells).fill(
        <TableCell>
          <Skeleton sx={{ zIndex: 0 }} />
        </TableCell>,
      )}
    </TableRow>
  );
};
