import { tag, text as textStyle, tagIcon as tagIconStyle } from './styles';
import BoxIcon from 'assets/box.svg';
import { IconDeliveryLine, IconUserDefault } from '@odekoteam/doppio';
import TimeClockChecked from 'assets/timeClockChecked.svg';

export enum TagIcons {
  box = 'box',
  deliveryLine = 'deliveryLine',
  iconUserDefault = 'iconUserDefault',
  timeClockChecked = 'timeClockChecked',
}

const TagIcon = ({ icon }: { icon?: TagIcons }): JSX.Element | null => {
  let tagIcon = null;
  if (icon === TagIcons.box) {
    tagIcon = (
      <div sx={{ paddingTop: '4px', marginRight: '11px' }}>
        <BoxIcon />
      </div>
    );
  }
  if (icon === TagIcons.deliveryLine) {
    tagIcon = (
      <div sx={{ paddingTop: '4px', marginRight: '11px' }}>
        <IconDeliveryLine height={24} width={24} />
      </div>
    );
  }
  if (icon === TagIcons.iconUserDefault) {
    tagIcon = (
      <div sx={{ paddingTop: '4px', marginRight: '4px' }}>
        <IconUserDefault height={24} width={24} />
      </div>
    );
  }
  if (icon === TagIcons.timeClockChecked) {
    tagIcon = (
      <div sx={{ paddingTop: '7px', marginRight: '4px' }}>
        <TimeClockChecked />
      </div>
    );
  }
  return tagIcon;
};

const Tag = ({
  text,
  style,
  icon,
}: {
  text: string;
  style?: React.CSSProperties;
  icon?: TagIcons;
}): JSX.Element | null => {
  const id = text.replaceAll(' ', '-').toLowerCase() + '-tag';

  let tagStyle =
    icon === undefined
      ? { ...tag, ...style }
      : { ...tag, ...style, ...tagIconStyle };

  return (
    <div sx={tagStyle} id={id}>
      <TagIcon icon={icon} />
      <span sx={textStyle}>{text}</span>
    </div>
  );
};

export default Tag;
