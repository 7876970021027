import { createAsyncThunk, PayloadAction } from '@reduxjs/toolkit';
import { GET_IS_ROUTES_SYNC_ENABLED } from 'src/graphql/requests/queries';
import { client } from 'src/graphql/client';
import { GGetIsRoutesSyncEnabledQuery } from 'src/graphql/types/generated';
import { DeliveryState } from './state';

export const getIsRoutesSyncEnabled = createAsyncThunk(
  'delivery/getIsRoutesSyncEnabled',
  async ({
    warehouseId,
    nightEnd,
  }: {
    warehouseId: string;
    nightEnd: string;
  }): Promise<boolean> => {
    const { data, error } = await client
      .query<GGetIsRoutesSyncEnabledQuery>(
        GET_IS_ROUTES_SYNC_ENABLED,
        { isRoutesSyncEnabledInput: { warehouseId, nightEnd } },
        { requestPolicy: 'cache-and-network' },
      )
      .toPromise();
    if (!data) {
      // TODO: do something with the error
      console.error('no GetIsRoutesSyncEnabled returned', error);
      return false;
    }
    return data.isRoutesSyncEnabled;
  },
);

export const getIsRoutesSyncEnabledFulfilled = (
  state: DeliveryState,
  action: PayloadAction<boolean>,
): void => {
  state.isRoutesSyncEnabled = action.payload;
};
