import { createAsyncThunk, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from 'src/state/store';
import { setRouteListCardVisible } from '.';
import { getRouteImportInfo } from './getRouteImportInfo';
import { getRoutes } from './getRoutes';
import { DeliveryState } from './state';

export const setNightEnd = createAsyncThunk(
  'delivery/setNightEnd',
  async (nightEnd: string, thunkAPI): Promise<string> => {
    const rootState: RootState = thunkAPI.getState() as RootState;
    const warehouseId = rootState.delivery.selectedWarehouseId;
    if (warehouseId) {
      thunkAPI.dispatch(getRoutes({ warehouseId, nightEnd }));
      thunkAPI.dispatch(getRouteImportInfo({ viewingNightEnd: nightEnd }));
    }
    thunkAPI.dispatch(setRouteListCardVisible());
    return nightEnd;
  },
);

export const setNightEndFulfilled = (
  state: DeliveryState,
  action: PayloadAction<string>,
): void => {
  state.nightEnd.viewing = action.payload;
  state.deliveryStatuses = {
    routeStatuses: [],
    stopStatuses: [],
    redeliveryStopStatuses: [],
    loading: false,
  };
};
