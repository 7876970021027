const NewTabLink = ({
  url,
  label,
  style,
}: {
  url: string;
  label: string;
  style?: React.CSSProperties;
}): JSX.Element | null => {
  const id = label.replaceAll(' ', '-').toLowerCase() + '-link';

  return (
    <a
      id={id}
      sx={{ ...style, variant: 'text.link' }}
      target="_blank"
      rel="noopener noreferrer"
      href={url}
    >
      {label}
    </a>
  );
};

export default NewTabLink;
