import { createAsyncThunk, PayloadAction } from '@reduxjs/toolkit';
import { client } from 'src/graphql/client';
import {
  GTriggerRoutesSyncInput,
  GTriggerRoutesSyncMutation,
} from 'src/graphql/types/generated';
import { TRIGGER_ROUTES_SYNC } from 'src/graphql/requests/mutations';
import { NotificationType, showNotification } from '../notification';
import { DeliveryState } from './state';

export const triggerRoutesSync = createAsyncThunk(
  'delivery/triggerRoutesSync',
  async (
    { warehouseId, nightEnd }: GTriggerRoutesSyncInput,
    thunkAPI,
  ): Promise<boolean> => {
    const { data, error } = await client
      .mutation<GTriggerRoutesSyncMutation>(TRIGGER_ROUTES_SYNC, {
        input: { warehouseId, nightEnd },
      })
      .toPromise();

    if (
      error ||
      !data?.triggerRoutesSync ||
      !data?.triggerRoutesSync?.triggered
    ) {
      const message = 'Routes synchronization could not be triggered.';
      thunkAPI.dispatch(
        showNotification({
          type: NotificationType.Error,
          message,
        }),
      );
      return false;
    }

    thunkAPI.dispatch(
      showNotification({
        type: NotificationType.Success,
        message:
          'Routes synchronization has been successfully triggered. It may take a few minutes to complete.',
      }),
    );
    return data?.triggerRoutesSync?.triggered;
  },
);

export const triggerRoutesSyncPending = (state: DeliveryState): void => {
  state.isRoutesSyncEnabled = false;
};

export const triggerRoutesSyncFulfillment = (
  state: DeliveryState,
  action: PayloadAction<boolean>,
): void => {
  // If the process was triggered, disable the button
  state.isRoutesSyncEnabled = !action.payload;
};
