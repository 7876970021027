import { ThemeUICSSObject } from 'theme-ui';

export const tag: ThemeUICSSObject = {
  fontFamily: 'body',
  variant: 'text.labelSm',
  color: 'secondaryText',
  height: '28px',
  padding: '6px 4px 6px 4px',
  borderRadius: 1,
  alignSelf: 'center',
  justifyContent: 'center',
  alignItems: 'center',
  backgroundColor: 'backgroundMedium',
  marginRight: '8px',
  // @see https://stackoverflow.com/questions/9450706/safari-font-weight-issue-text-too-bold
  '-webkit-font-smoothing': 'antialiased',
};

export const tagIcon: ThemeUICSSObject = {
  color: 'neutralText',
  display: 'inline-flex',
  flexDirection: 'row',
  justifyContent: 'center',
  alignItems: 'center',
  padding: '8px',
  height: '40px',
  variant: 'text.paragraphLg',
};

export const text: ThemeUICSSObject = {
  display: 'inline-block',
  width: 'max-content',
};
