import Tag from './Tag';
import { useQuery } from 'urql';
import { GGetWarehouseOrderCutOffTimeQuery } from 'src/graphql/types/generated';
import { GET_WAREHOUSE_ORDER_CUTOFF_TIME } from 'src/graphql/requests/queries';
import { isDefined } from 'src/utils';
import { selectedWarehouseSelector } from 'src/state/selectors/delivery';
import { useAppSelector } from 'src/hooks/store';
import Skeleton from 'react-loading-skeleton';
import { format, utcToZonedTime } from 'date-fns-tz';
import { Tooltip } from '@odekoteam/doppio';

const CUTOFF_TIME_TEXT = 'Odeko Warehouse Cutoff Time';

export enum CustomTagType {
  truncated = 'truncated',
  full = 'full',
}

const CutoffTag = ({
  stopId,
  tagType = CustomTagType.truncated,
}: {
  stopId?: string;
  tagType?: CustomTagType;
}): JSX.Element | null => {
  const selectedWarehouse = useAppSelector(selectedWarehouseSelector);

  const [{ fetching, data }] = useQuery<GGetWarehouseOrderCutOffTimeQuery>({
    query: GET_WAREHOUSE_ORDER_CUTOFF_TIME,
    variables: { input: { id: stopId } },
    requestPolicy: 'cache-and-network',
    pause: !isDefined(stopId),
  });

  if (fetching) return <Skeleton sx={{ width: '52px', height: '28px' }} />;

  let cutoffTime = '';
  const hasWarehouseOrder =
    data?.stop.__typename === 'Delivery'
      ? data?.stop.orders?.some((order) => {
          cutoffTime = order.dueAt;
          return order.vendor.isWarehouse === true;
        })
      : false;

  const timeZone = selectedWarehouse?.timeZone;

  if (!timeZone || cutoffTime === '') return null;

  const zonedTime = utcToZonedTime(cutoffTime, timeZone);
  const longTime = format(zonedTime, 'h:mm aa zzz', { timeZone });
  const shortTime = format(zonedTime, 'h:mm aa', { timeZone });

  if (!hasWarehouseOrder)
    return <Tag text="Vendor Order" style={{ maxWidth: '120px' }} />;

  const tagCopy = hasWarehouseOrder
    ? tagType === CustomTagType.truncated
      ? `${shortTime}`
      : `${longTime} Cutoff`
    : 'Vendor Order';

  return (
    <Tooltip labelText={CUTOFF_TIME_TEXT} placement="bottom">
      <span>
        <Tag text={tagCopy} style={{ maxWidth: '120px' }} />
      </span>
    </Tooltip>
  );
};

export default CutoffTag;
