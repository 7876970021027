import { ThemeUICSSObject } from 'theme-ui';

export const container: ThemeUICSSObject = {
  justifyContent: 'flex-end',
  marginBottom: '5px',
  alignSelf: 'flex-end',
  cursor: 'pointer',
};

export const datePickerWrapper: ThemeUICSSObject = {
  display: 'flex',
  alignItems: 'baseline',
};

export const deliveryDateLabel: ThemeUICSSObject = {
  display: 'flex',
  fontFamily: 'body',
  variant: 'text.paragraphLg',
  color: 'black',
};

export const selectedDateLabel: ThemeUICSSObject = {
  display: 'flex',
  fontFamily: 'heading',
  fontSize: '20px',
  lineHeight: '24px',
  color: 'black',
  marginLeft: '8px',
  marginRight: '15px',
};

export const datePickerIconStyle: ThemeUICSSObject = {
  width: '21px',
  height: '21px',
  transform: 'translateY(2px)',
};

export const arrowStyle: ThemeUICSSObject = {
  width: '14px',
  height: '12px',
  cursor: 'pointer',
};

export const calendarContainer: ThemeUICSSObject = {
  display: 'flex',
  background: 'white',
  boxShadow: 'standard',
  borderRadius: '12px',
  position: 'absolute',
  right: '20px',
  top: '140px',
  zIndex: 101,
  padding: '24px',
  width: '306px',
  height: '262px',
  alignItems: 'center',
  justifyContent: 'center',
};

export const calendarStyle: ThemeUICSSObject = {
  width: '100%',

  '.react-calendar, .react-calendar *, .react-calendar *:before, .react-calendar *:after':
    {
      mozBoxSizing: 'border-box',
      webkitBoxSizing: 'border-box',
      boxSizing: 'border-box',
    },
  '.react-calendar button': {
    margin: 0,
    border: 0,
    outline: 'none',
    '&:enabled:hover': {
      cursor: 'pointer',
    },
  },
  '.react-calendar__navigation': {
    display: 'flex',
    minWidth: '44px',
    background: 'none',
    marginBottom: '9px',
    justifyContent: 'center',
  },
  '.react-calendar__navigation button': {
    maxWidth: '100px',
    background: 'none',
    border: 0,
    fontFamily: 'body',
    fontSize: '18px',
    lineHeight: '22px',
  },

  '.react-calendar__month-view__weekdays__weekday': {
    fontFamily: 'body',
    textAlign: 'center',
    color: 'secondaryBackgroundActive',
    marginBottom: '10px',
  },

  'abbr[title]': {
    textDecoration: 'none',
  },

  '.react-calendar__tile': {
    width: '30px',
    height: '30px',
    border: 0,
    background: 'none',
    borderRadius: '4px',
    fontFamily: 'body',
    variant: 'text.labelLg',
  },
  '.react-calendar__tile:disabled': {
    color: 'secondaryBackgroundActive',
  },
  '.react-calendar__tile:enabled:hover': {
    backgroundColor: 'primaryBackgroundHover',
    cursor: 'pointer',
  },
  '.react-calendar__tile--now': {
    background: 'infoBackgroundWeak',
  },
  '.react-calendar__tile--now:enabled:hover, .react-calendar__tile--now:enabled:focus':
    {
      background: 'primaryBackgroundHover',
    },
  '.react-calendar__tile--active': {
    background: 'primaryBackgroundActive',
    borderRadius: '4px',
  },
  '.react-calendar__tile--active:enabled:hover': {
    background: 'primaryBackgroundHover',
  },
};
