import Logout from './Logout';
import {
  menuContainerStyle,
  innerContainerStyle,
  header,
  headerContainerStyle,
  exitContainer,
  page,
  notMenuStyle,
  issuesSymbol,
  warehouseOperationsSymbol,
} from './styles';
import NavLink from './NavLink';
import { isNavCollapsedSelector } from 'src/state/selectors/app';
import { useAppDispatch, useAppSelector } from 'src/hooks/store';
import { collapseNav } from 'src/state/slices/app';
import OdekoIcon from 'assets/odeko_drip_icon.svg';
import IssueSelected from 'assets/issueSelected.svg';
import IssueUnselected from 'assets/issueUnselected.svg';
import WarehouseOperationsSelected from 'assets/warehouseOperationsSelected.svg';
import WarehouseOperationsUnselected from 'assets/warehouseOperationsUnselected.svg';
import MapPinSelected from 'assets/mapPinSelected.svg';
import MapPinUnselected from 'assets/mapPinUnselected.svg';
import WarningOctagon from 'assets/warningOctagon.svg';
import WarningOctagonFilled from 'assets/warningOctagonFilled.svg';
import Search from 'assets/search.svg';
import SearchFilled from 'assets/searchFilled.svg';
import HelpCenter from './HelpCenter';
import {
  IconDeliveryFilled,
  IconClose,
  IconDeliveryLine,
  IconVerticalBarChartLine,
  IconVerticalBarChartFilled,
} from '@odekoteam/doppio';
import { useFlags } from 'launchdarkly-react-client-sdk';

export const deliveryPath = '/deliveries';
export const loginPath = '/login';
export const analyticsPath = '/analytics';
export const issuesPath = '/issues';
export const locationsPath = '/locations';
export const warehouseOperationsPath = '/warehouse-operations';
export const pickingLabelsPath = '/picking-labels';
export const fulfillmentDeltasPath = '/fulfillment-deltas';
export const fulfillmentDeltasPrintablePath = '/fulfillment-deltas-printable';
export const itemSearchPath = '/item-search';

const Navbar = (): JSX.Element | null => {
  const isNavCollapsed = useAppSelector(isNavCollapsedSelector);
  const dispatch = useAppDispatch();
  const {
    deliveryServiceShowAnalyticsPage,
    deliveryServiceFulfillmentDeltasPage,
  } = useFlags();

  const handleClick = (): void => {
    dispatch(collapseNav());
  };

  if (isNavCollapsed) return null;
  return (
    <div id="navbar" sx={page}>
      <div sx={menuContainerStyle}>
        <div sx={innerContainerStyle}>
          <div>
            <div id="navHeader" sx={headerContainerStyle}>
              <OdekoIcon />
              <span sx={header}>Route Management</span>
            </div>
            <div>
              <NavLink
                selectedIcon={<IconDeliveryFilled height={24} width={24} />}
                unselectedIcon={<IconDeliveryLine height={24} width={24} />}
                label="Deliveries"
                path={deliveryPath}
              />
              <NavLink
                selectedIcon={
                  <div sx={issuesSymbol}>
                    <IssueSelected />
                  </div>
                }
                unselectedIcon={
                  <div sx={issuesSymbol}>
                    <IssueUnselected />
                  </div>
                }
                label="Issues"
                path={issuesPath}
              />
              {deliveryServiceShowAnalyticsPage && (
                <NavLink
                  selectedIcon={
                    <IconVerticalBarChartFilled height={24} width={24} />
                  }
                  unselectedIcon={
                    <IconVerticalBarChartLine height={24} width={24} />
                  }
                  label="Analytics"
                  path={analyticsPath}
                />
              )}
              <NavLink
                selectedIcon={
                  <div sx={warehouseOperationsSymbol}>
                    <WarehouseOperationsSelected />
                  </div>
                }
                unselectedIcon={
                  <div sx={warehouseOperationsSymbol}>
                    <WarehouseOperationsUnselected />
                  </div>
                }
                label="Warehouse Operations"
                path={warehouseOperationsPath}
              />
              <NavLink
                selectedIcon={<MapPinSelected />}
                unselectedIcon={<MapPinUnselected />}
                label="Locations"
                path={locationsPath}
              />
              {deliveryServiceFulfillmentDeltasPage && (
                <NavLink
                  selectedIcon={<WarningOctagonFilled />}
                  unselectedIcon={<WarningOctagon />}
                  label="Fulfillment Deltas"
                  path={fulfillmentDeltasPath}
                />
              )}
              <NavLink
                selectedIcon={<SearchFilled />}
                unselectedIcon={<Search />}
                label="Item Search"
                path={itemSearchPath}
              />
            </div>
          </div>
          <div>
            <HelpCenter />
            <Logout />
          </div>
        </div>
        <div id="exitButton" sx={exitContainer} onClick={handleClick}>
          <IconClose width={24} height={24} />
        </div>
      </div>
      <div id="exitSpace" sx={notMenuStyle} onClick={handleClick} />
    </div>
  );
};

export default Navbar;
