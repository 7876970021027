/* eslint-disable max-nested-callbacks */
import { useEffect } from 'react';
import { useAppSelector } from '../../hooks/store';
import { authSelector } from '../../state/selectors/auth';
import { loginPath } from 'src/components/Navbar';
import { useNavigate, useParams } from 'react-router-dom';
import analytics from 'src/services/analytics';
import { Page, View, Document, StyleSheet } from '@react-pdf/renderer';
import { useQuery } from 'urql';
import { GET_PICKING_LABEL_DATA } from 'src/graphql/requests/queries';
import { GGetPickingLabelDataQuery } from 'src/graphql/types/generated';
import Label from './Label';
import { LabelData, listToMatrix } from './utils';
import { LoaderSpinner } from 'src/components/Loader';
import { naturalSort } from 'src/utils';
import { GetPickingLabelDataRoute } from 'src/graphql/types/delivery';

// Create styles
const styles = StyleSheet.create({
  page: {
    display: 'flex',
    flexDirection: 'column',
    textAlign: 'center',
    marginLeft: '24px',
  },
  row: {
    display: 'flex',
    flexDirection: 'row',
  },
});

const PickingLabels = (): JSX.Element => {
  const { warehouseId, warehouseName, nightEnd } = useParams();
  const navigate = useNavigate();
  const [{ data, fetching }] = useQuery<GGetPickingLabelDataQuery>({
    query: GET_PICKING_LABEL_DATA,
    variables: {
      input: { warehouseId: warehouseId, nightEnd: nightEnd },
    },
  });
  const { isUserAuthenticated } = useAppSelector(authSelector);

  useEffect(() => {
    analytics.track(`"PickingLabels" Page Visit`);
  }, []);

  useEffect(() => {
    if (!isUserAuthenticated) {
      navigate(loginPath);
    }
  }, [isUserAuthenticated, navigate]);

  if (!warehouseId || !nightEnd) {
    console.warn(
      `No warehouseId: ${warehouseId} or nightEnd: ${nightEnd} value found.`,
    );
    return (
      <div sx={{ variant: 'text.paragraphLg', margin: '20px' }}>
        Something went wrong.
      </div>
    );
  }
  if (fetching)
    return (
      <div sx={{ variant: 'text.paragraphLg', margin: '20px' }}>
        Loading picking labels, this may take a few minutes...
        <LoaderSpinner
          imgStyle={{
            top: 'auto',
            left: 'auto',
            height: '20px',
            margin: '2px 10px',
          }}
        />
      </div>
    );

  if (data) {
    const labelData: LabelData[] = [];
    const nonLobbyRoutes = (
      data?.routes.filter((route) => !route.isLobby) ?? []
    ).sort((aImpl: GetPickingLabelDataRoute, bImpl: GetPickingLabelDataRoute) =>
      naturalSort(aImpl.name, bImpl.name),
    );
    nonLobbyRoutes.forEach((route) => {
      route.stops?.forEach((stop) => {
        if (stop.__typename === 'Delivery') {
          const warehouseOrders = stop.orders?.filter(
            (order) => order.vendor.isWarehouse,
          );
          warehouseOrders?.forEach((order) => {
            const orderLinesWithQuantity = order.orderLines.filter(
              (orderLine) => orderLine.orderUnitQuantity > 0,
            );
            const orderLinesNotExcluded = orderLinesWithQuantity.filter(
              (orderLine) => !orderLine.sku.item?.excludedFromPickingLabels,
            );
            orderLinesNotExcluded.forEach((orderLine) => {
              Array.from({ length: orderLine.orderUnitQuantity }).forEach(
                (el, index) => {
                  labelData.push({
                    routeNumber: route.name.replace('Route ', ''),
                    stopNumber:
                      parseInt(stop.sequenceNumber ?? '?? -1', 10) + 1,
                    stopName: stop.location?.businessName ?? '',
                    itemName: orderLine.sku.item?.name ?? '',
                    quantityType: orderLine.quantityType,
                    itemsPerLot: orderLine.itemsPerLot,
                    orderQuantityIndex: `${index + 1} of ${
                      orderLine.orderUnitQuantity
                    }`,
                  });
                },
              );
            });
          });
        }
      });
    });
    const pickingLabelMatrix = listToMatrix(labelData, 4);

    return (
      <Document>
        <Page size="LETTER" style={styles.page} id="page">
          {pickingLabelMatrix.map((pickingLabelRow, i) => {
            const isFirst = i % 5 === 0 ? { marginTop: '48px' } : {};
            const isLast = (i + 1) % 5 === 0 ? { marginBottom: '48px' } : {};
            return (
              <View
                key={`row-${i}`}
                style={{ ...styles.row, ...isFirst, ...isLast }}
              >
                {pickingLabelRow.map((pickingLabel, j) => (
                  <Label
                    labelData={pickingLabel}
                    warehouseName={warehouseName ?? ''}
                    nightEnd={nightEnd ?? ''}
                    key={`row-${i}-cell-${j}`}
                    id={`row-${i}-cell-${j}`}
                  />
                ))}
              </View>
            );
          })}
        </Page>
      </Document>
    );
  }
  return <div>Something went wrong</div>;
};

export default PickingLabels;
