import {
  addDays,
  adjustForTimezone,
  formatTime24To12Hour,
  getYearMonthDay,
  isSameDayDeliveryWindow,
} from 'src/utils/date';
import DeliveryWindowIcon from 'assets/time_clock.svg';
import { Tooltip } from '@odekoteam/doppio';
import { Flex, ThemeUIStyleObject } from 'theme-ui';
import { nightEndSelector } from 'src/state/selectors/delivery';
import { useAppSelector } from 'src/hooks/store';

const smallLabel: ThemeUIStyleObject = {
  fontFamily: 'body',
  variant: 'text.labelSm',
  color: 'secondaryText',
  overflow: 'auto',
};

const svgWrapper: ThemeUIStyleObject = {
  display: 'flex',
  marginRight: '8px',
};

const addPlusOneDayStringToPMTime = (startTime?: string): String => {
  if (!startTime) return '';
  return isSameDayDeliveryWindow(startTime) ? '' : ' (+1 day)';
};

const DeliveryWindow = ({
  locationDeliveryWindow,
  stopId,
  style = {},
  showIcon = true,
  labelStyle,
  showDate = false,
}: {
  locationDeliveryWindow?: {
    startTime: string;
    endTime: string;
  };
  stopId: string;
  style?: ThemeUIStyleObject;
  showIcon?: Boolean;
  labelStyle?: ThemeUIStyleObject;
  showDate?: boolean;
}): JSX.Element | null => {
  const nightEnd = useAppSelector(nightEndSelector);
  let previousDateStr: string;
  if (nightEnd.viewing) {
    const previousDate = adjustForTimezone(
      addDays(-1, new Date(nightEnd.viewing)),
    );
    previousDateStr = getYearMonthDay(previousDate);
  }

  if (!locationDeliveryWindow) return null;

  const deliveryStartTime = formatTime24To12Hour(
    locationDeliveryWindow.startTime ?? '',
  );
  const deliveryEndTime = formatTime24To12Hour(
    locationDeliveryWindow.endTime ?? '',
  );
  const plusOneDayString = addPlusOneDayStringToPMTime(
    locationDeliveryWindow.startTime,
  );

  const getFormatStartDate = (): string => {
    if (isSameDayDeliveryWindow(locationDeliveryWindow.startTime))
      return ` (${nightEnd.viewing})`;
    return ` (${previousDateStr})`;
  };

  const formatStartDate = showDate ? getFormatStartDate() : '';
  const formatEndDate = showDate ? ` (${nightEnd.viewing})` : '';
  const deliveryWindowLabel = `${deliveryStartTime}${formatStartDate} - ${deliveryEndTime}${plusOneDayString}${formatEndDate}`;

  return (
    <Flex sx={style} id={`deliveryWindow-${stopId}`}>
      {showIcon && (
        <Tooltip labelText="Delivery Window" placement="top">
          <div sx={svgWrapper}>
            <DeliveryWindowIcon />
          </div>
        </Tooltip>
      )}
      <span sx={labelStyle ? { ...smallLabel, ...labelStyle } : smallLabel}>
        {deliveryWindowLabel}
      </span>
    </Flex>
  );
};

export default DeliveryWindow;
