import { ThemeUICSSObject } from 'theme-ui';

export const tableStyle: ThemeUICSSObject = {
  borderCollapse: 'collapse',
  width: '100%',
  marginBottom: '100px',
  variant: 'text.default',
};

export const cellStyle: ThemeUICSSObject = {
  padding: '16px',
  verticalAlign: 'top',
  textAlign: 'left',
};

export const rowStyle: ThemeUICSSObject = {
  borderBottom: '1px solid #E6E2DA',
  '&:hover': {
    backgroundColor: 'backgroundWeak',
  },
};
