import { GetRouteStopsDeliveryTimeStop } from 'src/graphql/types/delivery';

export type OperationRoute = {
  id: string;
  name: string;
  driverName: string;
};

export type PreviousStop = {
  stopId: string;
  prevStop: GetRouteStopsDeliveryTimeStop | null;
};

export enum SortByFilterValues {
  DeliveryTime = 'DeliveryTime',
  StopSequence = 'StopSequence',
}

export type OperationsState = {
  selectedRouteId: string | undefined;
  sortByFilterValue: SortByFilterValues;
};

export const initialState: OperationsState = {
  selectedRouteId: undefined,
  sortByFilterValue: SortByFilterValues.DeliveryTime,
};
